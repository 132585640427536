<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <Toolbar/>
        </v-col>
        <v-col cols="3">
          <v-text-field prepend-inner-icon="mdi-magnify"
                        clearable
                        v-model="keyword"
                        label="Recherche..."
                        outlined dense hide-details rounded></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete label="Campagne"
                          :items="campaigns"
                          v-model="filter.campaign_ids"
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          @change="getTasks"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          hide-details
                          prepend-inner-icon="mdi-filter-outline"></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-menu ref="menu"
                  v-model="menu"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="dates.join(' ~ ')"
                            dense
                            outlined
                            append-icon="mdi-calendar-outline"
                            prepend-inner-icon="mdi-filter-outline"
                            label="Date de planification"
                            hide-details
                            @click:clear="dates = []"
                            readonly
                            v-bind="attrs" v-on="on"
                            class="rounded mb-2"/>
            </template>
            <v-date-picker
                v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text class="rounded-lg"
                     @click="menu = false">
                <v-icon left>mdi-close</v-icon>
                Fermer
              </v-btn>
              <v-btn text color="primary"
                     :disabled="dates.length < 2"
                     class="rounded-lg"
                     @click="[menu = false,getTasks()]">
                <v-icon left>mdi-check</v-icon>
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>


        </v-col>
        <v-col cols="1">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="refresh()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualiser</span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-row v-if="loading">
        <v-col cols="12" lg="3" v-for="(el,i) in 8" :key="i">
          <v-card class="rounded-lg shadow">
            <v-skeleton-loader type="card,article"/>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && tasks.length === 0">
        <v-col cols="12">
          <v-card class="transparent" flat>
            <v-card-text class="text-center">
              <NoResult/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && tasks">
        <v-col cols="12"
               lg="3"
               class="d-flex flex-column"
               v-for="(task,i) in tasks" :key="i">

          <v-card class="rounded-lg shadow flex d-flex flex-column"
                  :to="'/backoffice/completed/'+task.task_id">

            <v-img v-if="task.task.photo"
                   :src="FILE_URL+task.task.photo"
                   min-height="180"
                   max-height="180"/>

            <v-img v-else
                   :src="require('@/assets/no-image.png')"
                   min-height="180"
                   max-height="180"/>

            <v-card-text class="flex">
              <div class="mb-3">

                <h4 class="mb-2">
                  <span class="text--primary">Campagne : </span>
                  {{ task.campaign.name }}
                </h4>

                <h4 class="mb-2">
                  <span class="text--primary">Tâche : </span>
                  {{ task.task.name }}
                </h4>

                <h4 class="text--primary mb-2">
                  <span class="text--primary"> Total Réponses : </span>
                  <v-chip color="blue" small dark>{{ task.responses_total }}</v-chip>
                </h4>

                <h4 class="text--primary mb-2">
                  <span class="text--primary"> Total des points : </span>
                  <v-chip color="orange" small dark>{{ task.winning_points_total }}</v-chip>
                </h4>

              </div>

              <div class="d-flex justify-space-between mb-1">
                <v-icon small left>mdi-check-circle-outline</v-icon>
                <span class="font-weight-bold">{{ task.validated_total }}</span>-Validées
                <v-spacer/>
                {{ task.validated_percent }}%
              </div>

              <v-progress-linear :value="task.validated_percent"
                                 striped
                                 :color="setColor(task.validated_percent)"
                                 rounded
                                 height="5"></v-progress-linear>

              <div class="d-flex justify-space-between mt-4 mb-1">
                <v-icon small left>mdi-close-circle-outline</v-icon>
                <span class="font-weight-bold">{{ task.refused_total }}</span>-Réfusées
                <v-spacer/>
                {{ task.refused_percent }}%
              </div>
              <v-progress-linear :value="task.refused_percent"
                                 striped
                                 :color="setColor(task.refused_percent)"
                                 rounded
                                 height="5"></v-progress-linear>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="total > 12">
        <v-col cols="12">
          <v-pagination total-visible="6"
                        circle
                        class="float-right"
                        v-model="pagination.current"
                        :length="pagination.total"
                        @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Toolbar from "../Toolbar";
import {HTTP} from "@/http-common";
import NoResult from "../../../components/NoResult";

export default {
  components: {NoResult, Toolbar},
  data() {
    return {
      loading: false,
      FILE_URL: process.env.VUE_APP_FILE_URL,

      tasks: [],
      total: 0,
      pagination: {
        current: 1,
        total: 0
      },

      keyword: '',
      dates: [],
      filter: {},
      menu: false
    }
  },
  methods: {
    getTasks() {
      this.loading = true
      this.$Progress.start()
      let filter = {
        campaign_ids: this.filter.campaign_ids,
        start_date: this.dates.length === 2 ? this.dates[0] : null,
        end_date: this.dates.length === 2 ? this.dates[1] : null,
      }
      HTTP.get('/tasks/completed/group?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          filter: filter,
        }
      }).then((res) => {
        this.tasks = res.data.data.data
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.loading = false
        this.$Progress.finish()
      }).catch(err => {
        this.loading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    onPageChange() {
      this.getTasks()
    },
    refresh() {
      this.dates = []
      this.filter = {}
      this.getTasks()
    },
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
    }
  },
  created() {
    this.getTasks()
  },
  watch: {
    keyword() {
      this.getTasks()
    }
  },
  computed: {
    campaigns() {
      return this.$store.state.campaigns
    }
  }
}
</script>

<style scoped>

</style>